import { FetchOptions } from 'ofetch';
import { AsyncDataOptions } from '#app';

// locals
import FetchFactory from '../factory';


class VehiculosElModule extends FetchFactory<T> {
  private RESOURCE = '/vehiculos-el';


  /**
   * Obtiene todos los filtros de vo
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
  async filters (
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions:FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          },
        };
        return this.call(
          'GET',
          `${this.RESOURCE}-filtros`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }


    /**
   * Obtiene todos los suggests
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
    async suggests (
      asyncDataOptions?: AsyncDataOptions<'json'>
    ) {
  
      return useAsyncData(
        () => {
          const fetchOptions:FetchOptions<'json'> = {
            headers: {
              'Accept': 'application/json'
            },
          };
          return this.call(
            'GET',
            `${this.RESOURCE}-suggests`,
            undefined, // body
            fetchOptions
          );
        },
        asyncDataOptions
      ); 
    }

   /**
   * Obtiene todos los filtros de vo
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
   async index (
    formData: FormData,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions:FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          },
        };
        return this.call(
          'GET',
          `${this.RESOURCE}?${new URLSearchParams(formData).toString()}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

   /**
   * @param asyncDataOptions options for `useAsyncData`
   * @returns 
   */
   async singleCar (
    formData: FormData,
    asyncDataOptions?: AsyncDataOptions<'json'>
  ) {

    return useAsyncData(
      () => {
        const fetchOptions:FetchOptions<'json'> = {
          headers: {
            'Accept': 'application/json'
          },
        };
        return this.call(
          'GET',
          `${this.RESOURCE}/${formData.get('id')}`,
          undefined, // body
          fetchOptions
        );
      },
      asyncDataOptions
    ); 
  }

}


export default VehiculosElModule;