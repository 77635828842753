import { default as indexpmPiN2xdGQMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/aviso-legal/index.vue?macro=true";
import { default as indexa8iXLJl2W9Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/cita-previa-taller/index.vue?macro=true";
import { default as _91detail_93wfSVP5T11jMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-nuevos/[detail].vue?macro=true";
import { default as indexGysdeV9BE9Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-nuevos/index.vue?macro=true";
import { default as _91detail_93syOWwt4YOkMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-nuevos/kilometro-cero/[detail].vue?macro=true";
import { default as indexaISRd9ckOaMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-nuevos/kilometro-cero/index.vue?macro=true";
import { default as _91detail_933sMzteYm32Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-ocasion/[detail].vue?macro=true";
import { default as indexA0BPqFTCnzMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-ocasion/index.vue?macro=true";
import { default as indexs7AoiVxa4iMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/empresas/index.vue?macro=true";
import { default as indexwQpPW2t9CgMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/favoritos/index.vue?macro=true";
import { default as indexyMw9cuw8pSMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/gama/[detalle]/index.vue?macro=true";
import { default as index0BrYkj8oVuMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/gama/index.vue?macro=true";
import { default as indexBFGItAzJAWMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/gracias/index.vue?macro=true";
import { default as indexBOZQfvix3oMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/index.vue?macro=true";
import { default as indexy4QTqldeVkMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/instalaciones/index.vue?macro=true";
import { default as _91detail_93J1YBvBljR4Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/motos-nuevas/[detail].vue?macro=true";
import { default as indexZLx4ZLM9EkMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/motos-nuevas/index.vue?macro=true";
import { default as _91detail_93KARUSqcgfkMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/motos-ocasion/[detail].vue?macro=true";
import { default as indexiu1dGQcdx6Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/motos-ocasion/index.vue?macro=true";
import { default as _91id_93Q7gzDU0Fa3Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/noticias/[id].vue?macro=true";
import { default as indexxvIiETAqEeMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/noticias/index.vue?macro=true";
import { default as indexbVVuEmi5o3Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/busquedas/index.vue?macro=true";
import { default as indexoHoyXq4VYMMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/comparador/index.vue?macro=true";
import { default as indexYcDjZrE290Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/favoritos/index.vue?macro=true";
import { default as indexU3SsIiWIqgMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/index.vue?macro=true";
import { default as index58dGeihFq1Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/perfil/index.vue?macro=true";
import { default as indexv9deguuD5TMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/reservas/index.vue?macro=true";
import { default as indexueWDUx7ctKMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/politica-de-cookies/index.vue?macro=true";
import { default as indexnrkPhaLLh0Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/politica-de-privacidad/index.vue?macro=true";
import { default as _91id_93z603Ob3SqIMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/promociones-marcas/[id].vue?macro=true";
import { default as index9WdsEWpE1uMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/promociones-marcas/index.vue?macro=true";
import { default as _91id_93yAJ9ynxeVMMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/promociones-posventa/[id].vue?macro=true";
import { default as indexairMoo737eMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/promociones-posventa/index.vue?macro=true";
import { default as _91slug_93zN0aGbTVRbMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/reservar/[type]/[slug].vue?macro=true";
import { default as indexZJRQmORz3qMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/reservar/error/index.vue?macro=true";
import { default as _91key2_93da9DE0iwtlMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/reservar/estado/[key1]/[key2].vue?macro=true";
import { default as _91key2_93bU6KFasF50Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/reservar/estado/imprimir/[key1]/[key2].vue?macro=true";
import { default as indexrzoi5YBWyzMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/seguros/index.vue?macro=true";
import { default as indexbXnWp9OfcTMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/sostenibilidad/index.vue?macro=true";
import { default as _91marca_93MUHGPwEEoBMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca].vue?macro=true";
import { default as indexNTRzQdRhEWMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/alianzas/index.vue?macro=true";
import { default as index60JMsRcIysMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/aviso-legal/index.vue?macro=true";
import { default as indexvueaYXi300Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/canal-denuncias/index.vue?macro=true";
import { default as indexK9CXPR6w1YMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/centros-chapa-y-pintura/[id]/index.vue?macro=true";
import { default as indexcEtVNlnkoZMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/centros-chapa-y-pintura/index.vue?macro=true";
import { default as indexFPqztnt7W2Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/cita-previa-taller/index.vue?macro=true";
import { default as _91detail_93wVQOPZIirNMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-electricos/[detail].vue?macro=true";
import { default as indexus2etQFi2WMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-electricos/index.vue?macro=true";
import { default as _91detail_93RXjAaOcGXjMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-nuevos/[detail].vue?macro=true";
import { default as indexlfUVwsdJEBMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-nuevos/index.vue?macro=true";
import { default as _91detail_93PSIkxpITw1Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-nuevos/kilometro-cero/[detail].vue?macro=true";
import { default as indexE4ojzABTC9Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-nuevos/kilometro-cero/index.vue?macro=true";
import { default as indexRTPfn5UlioMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/gracias/index.vue?macro=true";
import { default as indexUKG13trhCXMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/historia/index.vue?macro=true";
import { default as indexAf9LH5I1eNMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/index.vue?macro=true";
import { default as indexEBeKptuKRlMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/instalaciones/[id]/index.vue?macro=true";
import { default as indextItr6tpJEPMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/instalaciones/index.vue?macro=true";
import { default as indexmNg8PpVLXsMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/movilidad/index.vue?macro=true";
import { default as _91id_93rIxNk86qHZMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/noticias-electricos/[id].vue?macro=true";
import { default as indexYttrQ6ZQlFMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/noticias-electricos/index.vue?macro=true";
import { default as _91id_939OmwtcfhOcMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/noticias/[id].vue?macro=true";
import { default as indexSOM9Ve9kM8Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/noticias/index.vue?macro=true";
import { default as indexrrzaVqsq7GMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/busquedas/index.vue?macro=true";
import { default as indexnQjxUwgfYjMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/comparador/index.vue?macro=true";
import { default as indexIu0RiDJLHhMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/favoritos/index.vue?macro=true";
import { default as indexWsSrtQp0DrMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/index.vue?macro=true";
import { default as indexU7uFTEbBZLMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/perfil/index.vue?macro=true";
import { default as indexKcV3WmNdYGMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/reservas/index.vue?macro=true";
import { default as indexyukGnTpWniMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/politica-de-cookies/index.vue?macro=true";
import { default as indexAvd78ZjCHjMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/politica-de-privacidad/index.vue?macro=true";
import { default as index1g2qFGuRrhMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/promociones-marcas/index.vue?macro=true";
import { default as indexcUd9JP8kK1Meta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/promociones-posventa/index.vue?macro=true";
import { default as _91slug_93Uh6o6Z32klMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/reservar/[type]/[slug].vue?macro=true";
import { default as indexPp6YUxOazWMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/reservar/error/index.vue?macro=true";
import { default as _91key2_93tYzLSMnUyqMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/reservar/estado/[key1]/[key2].vue?macro=true";
import { default as _91key2_93uT1sMaWYzgMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/reservar/estado/imprimir/[key1]/[key2].vue?macro=true";
import { default as indexBX6VN3YSglMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/seguros/index.vue?macro=true";
import { default as indexDricjyo0RbMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/servicios-empresas/index.vue?macro=true";
import { default as indexdh9VTwtOrlMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/sostenibilidad/index.vue?macro=true";
import { default as _91id_93Losj9pMCQBMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/trabaja-con-nosotros/[id].vue?macro=true";
import { default as indexl9cUVwUXHtMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/trabaja-con-nosotros/index.vue?macro=true";
import { default as indexjOPVYRNMTUMeta } from "/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/user/recovery-password/[token1]/[token2]/index.vue?macro=true";
export default [
  {
    name: _91marca_93MUHGPwEEoBMeta?.name ?? undefined,
    path: _91marca_93MUHGPwEEoBMeta?.path ?? "/:marca()",
    meta: _91marca_93MUHGPwEEoBMeta || {},
    alias: _91marca_93MUHGPwEEoBMeta?.alias || [],
    redirect: _91marca_93MUHGPwEEoBMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca].vue").then(m => m.default || m),
    children: [
  {
    name: indexpmPiN2xdGQMeta?.name ?? "marca-aviso-legal",
    path: indexpmPiN2xdGQMeta?.path ?? "aviso-legal",
    meta: indexpmPiN2xdGQMeta || {},
    alias: indexpmPiN2xdGQMeta?.alias || [],
    redirect: indexpmPiN2xdGQMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/aviso-legal/index.vue").then(m => m.default || m)
  },
  {
    name: indexa8iXLJl2W9Meta?.name ?? "marca-cita-previa-taller",
    path: indexa8iXLJl2W9Meta?.path ?? "cita-previa-taller",
    meta: indexa8iXLJl2W9Meta || {},
    alias: indexa8iXLJl2W9Meta?.alias || [],
    redirect: indexa8iXLJl2W9Meta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/cita-previa-taller/index.vue").then(m => m.default || m)
  },
  {
    name: _91detail_93wfSVP5T11jMeta?.name ?? "marca-coches-nuevos-detail",
    path: _91detail_93wfSVP5T11jMeta?.path ?? "coches-nuevos/:detail()",
    meta: _91detail_93wfSVP5T11jMeta || {},
    alias: _91detail_93wfSVP5T11jMeta?.alias || [],
    redirect: _91detail_93wfSVP5T11jMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-nuevos/[detail].vue").then(m => m.default || m)
  },
  {
    name: indexGysdeV9BE9Meta?.name ?? "marca-coches-nuevos",
    path: indexGysdeV9BE9Meta?.path ?? "coches-nuevos",
    meta: indexGysdeV9BE9Meta || {},
    alias: indexGysdeV9BE9Meta?.alias || [],
    redirect: indexGysdeV9BE9Meta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-nuevos/index.vue").then(m => m.default || m)
  },
  {
    name: _91detail_93syOWwt4YOkMeta?.name ?? "marca-coches-nuevos-kilometro-cero-detail",
    path: _91detail_93syOWwt4YOkMeta?.path ?? "coches-nuevos/kilometro-cero/:detail()",
    meta: _91detail_93syOWwt4YOkMeta || {},
    alias: _91detail_93syOWwt4YOkMeta?.alias || [],
    redirect: _91detail_93syOWwt4YOkMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-nuevos/kilometro-cero/[detail].vue").then(m => m.default || m)
  },
  {
    name: indexaISRd9ckOaMeta?.name ?? "marca-coches-nuevos-kilometro-cero",
    path: indexaISRd9ckOaMeta?.path ?? "coches-nuevos/kilometro-cero",
    meta: indexaISRd9ckOaMeta || {},
    alias: indexaISRd9ckOaMeta?.alias || [],
    redirect: indexaISRd9ckOaMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-nuevos/kilometro-cero/index.vue").then(m => m.default || m)
  },
  {
    name: _91detail_933sMzteYm32Meta?.name ?? "marca-coches-ocasion-detail",
    path: _91detail_933sMzteYm32Meta?.path ?? "coches-ocasion/:detail()",
    meta: _91detail_933sMzteYm32Meta || {},
    alias: _91detail_933sMzteYm32Meta?.alias || [],
    redirect: _91detail_933sMzteYm32Meta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-ocasion/[detail].vue").then(m => m.default || m)
  },
  {
    name: indexA0BPqFTCnzMeta?.name ?? "marca-coches-ocasion",
    path: indexA0BPqFTCnzMeta?.path ?? "coches-ocasion",
    meta: indexA0BPqFTCnzMeta || {},
    alias: indexA0BPqFTCnzMeta?.alias || [],
    redirect: indexA0BPqFTCnzMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/coches-ocasion/index.vue").then(m => m.default || m)
  },
  {
    name: indexs7AoiVxa4iMeta?.name ?? "marca-empresas",
    path: indexs7AoiVxa4iMeta?.path ?? "empresas",
    meta: indexs7AoiVxa4iMeta || {},
    alias: indexs7AoiVxa4iMeta?.alias || [],
    redirect: indexs7AoiVxa4iMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/empresas/index.vue").then(m => m.default || m)
  },
  {
    name: indexwQpPW2t9CgMeta?.name ?? "marca-favoritos",
    path: indexwQpPW2t9CgMeta?.path ?? "favoritos",
    meta: indexwQpPW2t9CgMeta || {},
    alias: indexwQpPW2t9CgMeta?.alias || [],
    redirect: indexwQpPW2t9CgMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/favoritos/index.vue").then(m => m.default || m)
  },
  {
    name: indexyMw9cuw8pSMeta?.name ?? "marca-gama-detalle",
    path: indexyMw9cuw8pSMeta?.path ?? "gama/:detalle()",
    meta: indexyMw9cuw8pSMeta || {},
    alias: indexyMw9cuw8pSMeta?.alias || [],
    redirect: indexyMw9cuw8pSMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/gama/[detalle]/index.vue").then(m => m.default || m)
  },
  {
    name: index0BrYkj8oVuMeta?.name ?? "marca-gama",
    path: index0BrYkj8oVuMeta?.path ?? "gama",
    meta: index0BrYkj8oVuMeta || {},
    alias: index0BrYkj8oVuMeta?.alias || [],
    redirect: index0BrYkj8oVuMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/gama/index.vue").then(m => m.default || m)
  },
  {
    name: indexBFGItAzJAWMeta?.name ?? "marca-gracias",
    path: indexBFGItAzJAWMeta?.path ?? "gracias",
    meta: indexBFGItAzJAWMeta || {},
    alias: indexBFGItAzJAWMeta?.alias || [],
    redirect: indexBFGItAzJAWMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/gracias/index.vue").then(m => m.default || m)
  },
  {
    name: indexBOZQfvix3oMeta?.name ?? "marca",
    path: indexBOZQfvix3oMeta?.path ?? "",
    meta: indexBOZQfvix3oMeta || {},
    alias: indexBOZQfvix3oMeta?.alias || [],
    redirect: indexBOZQfvix3oMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/index.vue").then(m => m.default || m)
  },
  {
    name: indexy4QTqldeVkMeta?.name ?? "marca-instalaciones",
    path: indexy4QTqldeVkMeta?.path ?? "instalaciones",
    meta: indexy4QTqldeVkMeta || {},
    alias: indexy4QTqldeVkMeta?.alias || [],
    redirect: indexy4QTqldeVkMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/instalaciones/index.vue").then(m => m.default || m)
  },
  {
    name: _91detail_93J1YBvBljR4Meta?.name ?? "marca-motos-nuevas-detail",
    path: _91detail_93J1YBvBljR4Meta?.path ?? "motos-nuevas/:detail()",
    meta: _91detail_93J1YBvBljR4Meta || {},
    alias: _91detail_93J1YBvBljR4Meta?.alias || [],
    redirect: _91detail_93J1YBvBljR4Meta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/motos-nuevas/[detail].vue").then(m => m.default || m)
  },
  {
    name: indexZLx4ZLM9EkMeta?.name ?? "marca-motos-nuevas",
    path: indexZLx4ZLM9EkMeta?.path ?? "motos-nuevas",
    meta: indexZLx4ZLM9EkMeta || {},
    alias: indexZLx4ZLM9EkMeta?.alias || [],
    redirect: indexZLx4ZLM9EkMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/motos-nuevas/index.vue").then(m => m.default || m)
  },
  {
    name: _91detail_93KARUSqcgfkMeta?.name ?? "marca-motos-ocasion-detail",
    path: _91detail_93KARUSqcgfkMeta?.path ?? "motos-ocasion/:detail()",
    meta: _91detail_93KARUSqcgfkMeta || {},
    alias: _91detail_93KARUSqcgfkMeta?.alias || [],
    redirect: _91detail_93KARUSqcgfkMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/motos-ocasion/[detail].vue").then(m => m.default || m)
  },
  {
    name: indexiu1dGQcdx6Meta?.name ?? "marca-motos-ocasion",
    path: indexiu1dGQcdx6Meta?.path ?? "motos-ocasion",
    meta: indexiu1dGQcdx6Meta || {},
    alias: indexiu1dGQcdx6Meta?.alias || [],
    redirect: indexiu1dGQcdx6Meta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/motos-ocasion/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Q7gzDU0Fa3Meta?.name ?? "marca-noticias-id",
    path: _91id_93Q7gzDU0Fa3Meta?.path ?? "noticias/:id()",
    meta: _91id_93Q7gzDU0Fa3Meta || {},
    alias: _91id_93Q7gzDU0Fa3Meta?.alias || [],
    redirect: _91id_93Q7gzDU0Fa3Meta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/noticias/[id].vue").then(m => m.default || m)
  },
  {
    name: indexxvIiETAqEeMeta?.name ?? "marca-noticias",
    path: indexxvIiETAqEeMeta?.path ?? "noticias",
    meta: indexxvIiETAqEeMeta || {},
    alias: indexxvIiETAqEeMeta?.alias || [],
    redirect: indexxvIiETAqEeMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/noticias/index.vue").then(m => m.default || m)
  },
  {
    name: indexbVVuEmi5o3Meta?.name ?? "marca-panel-busquedas",
    path: indexbVVuEmi5o3Meta?.path ?? "panel/busquedas",
    meta: indexbVVuEmi5o3Meta || {},
    alias: indexbVVuEmi5o3Meta?.alias || [],
    redirect: indexbVVuEmi5o3Meta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/busquedas/index.vue").then(m => m.default || m)
  },
  {
    name: indexoHoyXq4VYMMeta?.name ?? "marca-panel-comparador",
    path: indexoHoyXq4VYMMeta?.path ?? "panel/comparador",
    meta: indexoHoyXq4VYMMeta || {},
    alias: indexoHoyXq4VYMMeta?.alias || [],
    redirect: indexoHoyXq4VYMMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/comparador/index.vue").then(m => m.default || m)
  },
  {
    name: indexYcDjZrE290Meta?.name ?? "marca-panel-favoritos",
    path: indexYcDjZrE290Meta?.path ?? "panel/favoritos",
    meta: indexYcDjZrE290Meta || {},
    alias: indexYcDjZrE290Meta?.alias || [],
    redirect: indexYcDjZrE290Meta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/favoritos/index.vue").then(m => m.default || m)
  },
  {
    name: indexU3SsIiWIqgMeta?.name ?? "marca-panel",
    path: indexU3SsIiWIqgMeta?.path ?? "panel",
    meta: indexU3SsIiWIqgMeta || {},
    alias: indexU3SsIiWIqgMeta?.alias || [],
    redirect: indexU3SsIiWIqgMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/index.vue").then(m => m.default || m)
  },
  {
    name: index58dGeihFq1Meta?.name ?? "marca-panel-perfil",
    path: index58dGeihFq1Meta?.path ?? "panel/perfil",
    meta: index58dGeihFq1Meta || {},
    alias: index58dGeihFq1Meta?.alias || [],
    redirect: index58dGeihFq1Meta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/perfil/index.vue").then(m => m.default || m)
  },
  {
    name: indexv9deguuD5TMeta?.name ?? "marca-panel-reservas",
    path: indexv9deguuD5TMeta?.path ?? "panel/reservas",
    meta: indexv9deguuD5TMeta || {},
    alias: indexv9deguuD5TMeta?.alias || [],
    redirect: indexv9deguuD5TMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/panel/reservas/index.vue").then(m => m.default || m)
  },
  {
    name: indexueWDUx7ctKMeta?.name ?? "marca-politica-de-cookies",
    path: indexueWDUx7ctKMeta?.path ?? "politica-de-cookies",
    meta: indexueWDUx7ctKMeta || {},
    alias: indexueWDUx7ctKMeta?.alias || [],
    redirect: indexueWDUx7ctKMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/politica-de-cookies/index.vue").then(m => m.default || m)
  },
  {
    name: indexnrkPhaLLh0Meta?.name ?? "marca-politica-de-privacidad",
    path: indexnrkPhaLLh0Meta?.path ?? "politica-de-privacidad",
    meta: indexnrkPhaLLh0Meta || {},
    alias: indexnrkPhaLLh0Meta?.alias || [],
    redirect: indexnrkPhaLLh0Meta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/politica-de-privacidad/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93z603Ob3SqIMeta?.name ?? "marca-promociones-marcas-id",
    path: _91id_93z603Ob3SqIMeta?.path ?? "promociones-marcas/:id()",
    meta: _91id_93z603Ob3SqIMeta || {},
    alias: _91id_93z603Ob3SqIMeta?.alias || [],
    redirect: _91id_93z603Ob3SqIMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/promociones-marcas/[id].vue").then(m => m.default || m)
  },
  {
    name: index9WdsEWpE1uMeta?.name ?? "marca-promociones-marcas",
    path: index9WdsEWpE1uMeta?.path ?? "promociones-marcas",
    meta: index9WdsEWpE1uMeta || {},
    alias: index9WdsEWpE1uMeta?.alias || [],
    redirect: index9WdsEWpE1uMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/promociones-marcas/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yAJ9ynxeVMMeta?.name ?? "marca-promociones-posventa-id",
    path: _91id_93yAJ9ynxeVMMeta?.path ?? "promociones-posventa/:id()",
    meta: _91id_93yAJ9ynxeVMMeta || {},
    alias: _91id_93yAJ9ynxeVMMeta?.alias || [],
    redirect: _91id_93yAJ9ynxeVMMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/promociones-posventa/[id].vue").then(m => m.default || m)
  },
  {
    name: indexairMoo737eMeta?.name ?? "marca-promociones-posventa",
    path: indexairMoo737eMeta?.path ?? "promociones-posventa",
    meta: indexairMoo737eMeta || {},
    alias: indexairMoo737eMeta?.alias || [],
    redirect: indexairMoo737eMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/promociones-posventa/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93zN0aGbTVRbMeta?.name ?? "marca-reservar-type-slug",
    path: _91slug_93zN0aGbTVRbMeta?.path ?? "reservar/:type()/:slug()",
    meta: _91slug_93zN0aGbTVRbMeta || {},
    alias: _91slug_93zN0aGbTVRbMeta?.alias || [],
    redirect: _91slug_93zN0aGbTVRbMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/reservar/[type]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexZJRQmORz3qMeta?.name ?? "marca-reservar-error",
    path: indexZJRQmORz3qMeta?.path ?? "reservar/error",
    meta: indexZJRQmORz3qMeta || {},
    alias: indexZJRQmORz3qMeta?.alias || [],
    redirect: indexZJRQmORz3qMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/reservar/error/index.vue").then(m => m.default || m)
  },
  {
    name: _91key2_93da9DE0iwtlMeta?.name ?? "marca-reservar-estado-key1-key2",
    path: _91key2_93da9DE0iwtlMeta?.path ?? "reservar/estado/:key1()/:key2()",
    meta: _91key2_93da9DE0iwtlMeta || {},
    alias: _91key2_93da9DE0iwtlMeta?.alias || [],
    redirect: _91key2_93da9DE0iwtlMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/reservar/estado/[key1]/[key2].vue").then(m => m.default || m)
  },
  {
    name: _91key2_93bU6KFasF50Meta?.name ?? "marca-reservar-estado-imprimir-key1-key2",
    path: _91key2_93bU6KFasF50Meta?.path ?? "reservar/estado/imprimir/:key1()/:key2()",
    meta: _91key2_93bU6KFasF50Meta || {},
    alias: _91key2_93bU6KFasF50Meta?.alias || [],
    redirect: _91key2_93bU6KFasF50Meta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/reservar/estado/imprimir/[key1]/[key2].vue").then(m => m.default || m)
  },
  {
    name: indexrzoi5YBWyzMeta?.name ?? "marca-seguros",
    path: indexrzoi5YBWyzMeta?.path ?? "seguros",
    meta: indexrzoi5YBWyzMeta || {},
    alias: indexrzoi5YBWyzMeta?.alias || [],
    redirect: indexrzoi5YBWyzMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/seguros/index.vue").then(m => m.default || m)
  },
  {
    name: indexbXnWp9OfcTMeta?.name ?? "marca-sostenibilidad",
    path: indexbXnWp9OfcTMeta?.path ?? "sostenibilidad",
    meta: indexbXnWp9OfcTMeta || {},
    alias: indexbXnWp9OfcTMeta?.alias || [],
    redirect: indexbXnWp9OfcTMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/[marca]/sostenibilidad/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexNTRzQdRhEWMeta?.name ?? "alianzas",
    path: indexNTRzQdRhEWMeta?.path ?? "/alianzas",
    meta: indexNTRzQdRhEWMeta || {},
    alias: indexNTRzQdRhEWMeta?.alias || [],
    redirect: indexNTRzQdRhEWMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/alianzas/index.vue").then(m => m.default || m)
  },
  {
    name: index60JMsRcIysMeta?.name ?? "aviso-legal",
    path: index60JMsRcIysMeta?.path ?? "/aviso-legal",
    meta: index60JMsRcIysMeta || {},
    alias: index60JMsRcIysMeta?.alias || [],
    redirect: index60JMsRcIysMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/aviso-legal/index.vue").then(m => m.default || m)
  },
  {
    name: indexvueaYXi300Meta?.name ?? "canal-denuncias",
    path: indexvueaYXi300Meta?.path ?? "/canal-denuncias",
    meta: indexvueaYXi300Meta || {},
    alias: indexvueaYXi300Meta?.alias || [],
    redirect: indexvueaYXi300Meta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/canal-denuncias/index.vue").then(m => m.default || m)
  },
  {
    name: indexK9CXPR6w1YMeta?.name ?? "centros-chapa-y-pintura-id",
    path: indexK9CXPR6w1YMeta?.path ?? "/centros-chapa-y-pintura/:id()",
    meta: indexK9CXPR6w1YMeta || {},
    alias: indexK9CXPR6w1YMeta?.alias || [],
    redirect: indexK9CXPR6w1YMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/centros-chapa-y-pintura/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexcEtVNlnkoZMeta?.name ?? "centros-chapa-y-pintura",
    path: indexcEtVNlnkoZMeta?.path ?? "/centros-chapa-y-pintura",
    meta: indexcEtVNlnkoZMeta || {},
    alias: indexcEtVNlnkoZMeta?.alias || [],
    redirect: indexcEtVNlnkoZMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/centros-chapa-y-pintura/index.vue").then(m => m.default || m)
  },
  {
    name: indexFPqztnt7W2Meta?.name ?? "cita-previa-taller",
    path: indexFPqztnt7W2Meta?.path ?? "/cita-previa-taller",
    meta: indexFPqztnt7W2Meta || {},
    alias: indexFPqztnt7W2Meta?.alias || [],
    redirect: indexFPqztnt7W2Meta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/cita-previa-taller/index.vue").then(m => m.default || m)
  },
  {
    name: _91detail_93wVQOPZIirNMeta?.name ?? "coches-electricos-detail",
    path: _91detail_93wVQOPZIirNMeta?.path ?? "/coches-electricos/:detail()",
    meta: _91detail_93wVQOPZIirNMeta || {},
    alias: _91detail_93wVQOPZIirNMeta?.alias || [],
    redirect: _91detail_93wVQOPZIirNMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-electricos/[detail].vue").then(m => m.default || m)
  },
  {
    name: indexus2etQFi2WMeta?.name ?? "coches-electricos",
    path: indexus2etQFi2WMeta?.path ?? "/coches-electricos",
    meta: indexus2etQFi2WMeta || {},
    alias: indexus2etQFi2WMeta?.alias || [],
    redirect: indexus2etQFi2WMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-electricos/index.vue").then(m => m.default || m)
  },
  {
    name: _91detail_93RXjAaOcGXjMeta?.name ?? "coches-nuevos-detail",
    path: _91detail_93RXjAaOcGXjMeta?.path ?? "/coches-nuevos/:detail()",
    meta: _91detail_93RXjAaOcGXjMeta || {},
    alias: _91detail_93RXjAaOcGXjMeta?.alias || [],
    redirect: _91detail_93RXjAaOcGXjMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-nuevos/[detail].vue").then(m => m.default || m)
  },
  {
    name: indexlfUVwsdJEBMeta?.name ?? "coches-nuevos",
    path: indexlfUVwsdJEBMeta?.path ?? "/coches-nuevos",
    meta: indexlfUVwsdJEBMeta || {},
    alias: indexlfUVwsdJEBMeta?.alias || [],
    redirect: indexlfUVwsdJEBMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-nuevos/index.vue").then(m => m.default || m)
  },
  {
    name: _91detail_93PSIkxpITw1Meta?.name ?? "coches-nuevos-kilometro-cero-detail",
    path: _91detail_93PSIkxpITw1Meta?.path ?? "/coches-nuevos/kilometro-cero/:detail()",
    meta: _91detail_93PSIkxpITw1Meta || {},
    alias: _91detail_93PSIkxpITw1Meta?.alias || [],
    redirect: _91detail_93PSIkxpITw1Meta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-nuevos/kilometro-cero/[detail].vue").then(m => m.default || m)
  },
  {
    name: indexE4ojzABTC9Meta?.name ?? "coches-nuevos-kilometro-cero",
    path: indexE4ojzABTC9Meta?.path ?? "/coches-nuevos/kilometro-cero",
    meta: indexE4ojzABTC9Meta || {},
    alias: indexE4ojzABTC9Meta?.alias || [],
    redirect: indexE4ojzABTC9Meta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/coches-nuevos/kilometro-cero/index.vue").then(m => m.default || m)
  },
  {
    name: indexRTPfn5UlioMeta?.name ?? "gracias",
    path: indexRTPfn5UlioMeta?.path ?? "/gracias",
    meta: indexRTPfn5UlioMeta || {},
    alias: indexRTPfn5UlioMeta?.alias || [],
    redirect: indexRTPfn5UlioMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/gracias/index.vue").then(m => m.default || m)
  },
  {
    name: indexUKG13trhCXMeta?.name ?? "historia",
    path: indexUKG13trhCXMeta?.path ?? "/historia",
    meta: indexUKG13trhCXMeta || {},
    alias: indexUKG13trhCXMeta?.alias || [],
    redirect: indexUKG13trhCXMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/historia/index.vue").then(m => m.default || m)
  },
  {
    name: indexAf9LH5I1eNMeta?.name ?? "index",
    path: indexAf9LH5I1eNMeta?.path ?? "/",
    meta: indexAf9LH5I1eNMeta || {},
    alias: indexAf9LH5I1eNMeta?.alias || [],
    redirect: indexAf9LH5I1eNMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexEBeKptuKRlMeta?.name ?? "instalaciones-id",
    path: indexEBeKptuKRlMeta?.path ?? "/instalaciones/:id()",
    meta: indexEBeKptuKRlMeta || {},
    alias: indexEBeKptuKRlMeta?.alias || [],
    redirect: indexEBeKptuKRlMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/instalaciones/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indextItr6tpJEPMeta?.name ?? "instalaciones",
    path: indextItr6tpJEPMeta?.path ?? "/instalaciones",
    meta: indextItr6tpJEPMeta || {},
    alias: indextItr6tpJEPMeta?.alias || [],
    redirect: indextItr6tpJEPMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/instalaciones/index.vue").then(m => m.default || m)
  },
  {
    name: indexmNg8PpVLXsMeta?.name ?? "movilidad",
    path: indexmNg8PpVLXsMeta?.path ?? "/movilidad",
    meta: indexmNg8PpVLXsMeta || {},
    alias: indexmNg8PpVLXsMeta?.alias || [],
    redirect: indexmNg8PpVLXsMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/movilidad/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93rIxNk86qHZMeta?.name ?? "noticias-electricos-id",
    path: _91id_93rIxNk86qHZMeta?.path ?? "/noticias-electricos/:id()",
    meta: _91id_93rIxNk86qHZMeta || {},
    alias: _91id_93rIxNk86qHZMeta?.alias || [],
    redirect: _91id_93rIxNk86qHZMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/noticias-electricos/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYttrQ6ZQlFMeta?.name ?? "noticias-electricos",
    path: indexYttrQ6ZQlFMeta?.path ?? "/noticias-electricos",
    meta: indexYttrQ6ZQlFMeta || {},
    alias: indexYttrQ6ZQlFMeta?.alias || [],
    redirect: indexYttrQ6ZQlFMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/noticias-electricos/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_939OmwtcfhOcMeta?.name ?? "noticias-id",
    path: _91id_939OmwtcfhOcMeta?.path ?? "/noticias/:id()",
    meta: _91id_939OmwtcfhOcMeta || {},
    alias: _91id_939OmwtcfhOcMeta?.alias || [],
    redirect: _91id_939OmwtcfhOcMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/noticias/[id].vue").then(m => m.default || m)
  },
  {
    name: indexSOM9Ve9kM8Meta?.name ?? "noticias",
    path: indexSOM9Ve9kM8Meta?.path ?? "/noticias",
    meta: indexSOM9Ve9kM8Meta || {},
    alias: indexSOM9Ve9kM8Meta?.alias || [],
    redirect: indexSOM9Ve9kM8Meta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/noticias/index.vue").then(m => m.default || m)
  },
  {
    name: indexrrzaVqsq7GMeta?.name ?? "panel-busquedas",
    path: indexrrzaVqsq7GMeta?.path ?? "/panel/busquedas",
    meta: indexrrzaVqsq7GMeta || {},
    alias: indexrrzaVqsq7GMeta?.alias || [],
    redirect: indexrrzaVqsq7GMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/busquedas/index.vue").then(m => m.default || m)
  },
  {
    name: indexnQjxUwgfYjMeta?.name ?? "panel-comparador",
    path: indexnQjxUwgfYjMeta?.path ?? "/panel/comparador",
    meta: indexnQjxUwgfYjMeta || {},
    alias: indexnQjxUwgfYjMeta?.alias || [],
    redirect: indexnQjxUwgfYjMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/comparador/index.vue").then(m => m.default || m)
  },
  {
    name: indexIu0RiDJLHhMeta?.name ?? "panel-favoritos",
    path: indexIu0RiDJLHhMeta?.path ?? "/panel/favoritos",
    meta: indexIu0RiDJLHhMeta || {},
    alias: indexIu0RiDJLHhMeta?.alias || [],
    redirect: indexIu0RiDJLHhMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/favoritos/index.vue").then(m => m.default || m)
  },
  {
    name: indexWsSrtQp0DrMeta?.name ?? "panel",
    path: indexWsSrtQp0DrMeta?.path ?? "/panel",
    meta: indexWsSrtQp0DrMeta || {},
    alias: indexWsSrtQp0DrMeta?.alias || [],
    redirect: indexWsSrtQp0DrMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/index.vue").then(m => m.default || m)
  },
  {
    name: indexU7uFTEbBZLMeta?.name ?? "panel-perfil",
    path: indexU7uFTEbBZLMeta?.path ?? "/panel/perfil",
    meta: indexU7uFTEbBZLMeta || {},
    alias: indexU7uFTEbBZLMeta?.alias || [],
    redirect: indexU7uFTEbBZLMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/perfil/index.vue").then(m => m.default || m)
  },
  {
    name: indexKcV3WmNdYGMeta?.name ?? "panel-reservas",
    path: indexKcV3WmNdYGMeta?.path ?? "/panel/reservas",
    meta: indexKcV3WmNdYGMeta || {},
    alias: indexKcV3WmNdYGMeta?.alias || [],
    redirect: indexKcV3WmNdYGMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/panel/reservas/index.vue").then(m => m.default || m)
  },
  {
    name: indexyukGnTpWniMeta?.name ?? "politica-de-cookies",
    path: indexyukGnTpWniMeta?.path ?? "/politica-de-cookies",
    meta: indexyukGnTpWniMeta || {},
    alias: indexyukGnTpWniMeta?.alias || [],
    redirect: indexyukGnTpWniMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/politica-de-cookies/index.vue").then(m => m.default || m)
  },
  {
    name: indexAvd78ZjCHjMeta?.name ?? "politica-de-privacidad",
    path: indexAvd78ZjCHjMeta?.path ?? "/politica-de-privacidad",
    meta: indexAvd78ZjCHjMeta || {},
    alias: indexAvd78ZjCHjMeta?.alias || [],
    redirect: indexAvd78ZjCHjMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/politica-de-privacidad/index.vue").then(m => m.default || m)
  },
  {
    name: index1g2qFGuRrhMeta?.name ?? "promociones-marcas",
    path: index1g2qFGuRrhMeta?.path ?? "/promociones-marcas",
    meta: index1g2qFGuRrhMeta || {},
    alias: index1g2qFGuRrhMeta?.alias || [],
    redirect: index1g2qFGuRrhMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/promociones-marcas/index.vue").then(m => m.default || m)
  },
  {
    name: indexcUd9JP8kK1Meta?.name ?? "promociones-posventa",
    path: indexcUd9JP8kK1Meta?.path ?? "/promociones-posventa",
    meta: indexcUd9JP8kK1Meta || {},
    alias: indexcUd9JP8kK1Meta?.alias || [],
    redirect: indexcUd9JP8kK1Meta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/promociones-posventa/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93Uh6o6Z32klMeta?.name ?? "reservar-type-slug",
    path: _91slug_93Uh6o6Z32klMeta?.path ?? "/reservar/:type()/:slug()",
    meta: _91slug_93Uh6o6Z32klMeta || {},
    alias: _91slug_93Uh6o6Z32klMeta?.alias || [],
    redirect: _91slug_93Uh6o6Z32klMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/reservar/[type]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexPp6YUxOazWMeta?.name ?? "reservar-error",
    path: indexPp6YUxOazWMeta?.path ?? "/reservar/error",
    meta: indexPp6YUxOazWMeta || {},
    alias: indexPp6YUxOazWMeta?.alias || [],
    redirect: indexPp6YUxOazWMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/reservar/error/index.vue").then(m => m.default || m)
  },
  {
    name: _91key2_93tYzLSMnUyqMeta?.name ?? "reservar-estado-key1-key2",
    path: _91key2_93tYzLSMnUyqMeta?.path ?? "/reservar/estado/:key1()/:key2()",
    meta: _91key2_93tYzLSMnUyqMeta || {},
    alias: _91key2_93tYzLSMnUyqMeta?.alias || [],
    redirect: _91key2_93tYzLSMnUyqMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/reservar/estado/[key1]/[key2].vue").then(m => m.default || m)
  },
  {
    name: _91key2_93uT1sMaWYzgMeta?.name ?? "reservar-estado-imprimir-key1-key2",
    path: _91key2_93uT1sMaWYzgMeta?.path ?? "/reservar/estado/imprimir/:key1()/:key2()",
    meta: _91key2_93uT1sMaWYzgMeta || {},
    alias: _91key2_93uT1sMaWYzgMeta?.alias || [],
    redirect: _91key2_93uT1sMaWYzgMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/reservar/estado/imprimir/[key1]/[key2].vue").then(m => m.default || m)
  },
  {
    name: indexBX6VN3YSglMeta?.name ?? "seguros",
    path: indexBX6VN3YSglMeta?.path ?? "/seguros",
    meta: indexBX6VN3YSglMeta || {},
    alias: indexBX6VN3YSglMeta?.alias || [],
    redirect: indexBX6VN3YSglMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/seguros/index.vue").then(m => m.default || m)
  },
  {
    name: indexDricjyo0RbMeta?.name ?? "servicios-empresas",
    path: indexDricjyo0RbMeta?.path ?? "/servicios-empresas",
    meta: indexDricjyo0RbMeta || {},
    alias: indexDricjyo0RbMeta?.alias || [],
    redirect: indexDricjyo0RbMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/servicios-empresas/index.vue").then(m => m.default || m)
  },
  {
    name: indexdh9VTwtOrlMeta?.name ?? "sostenibilidad",
    path: indexdh9VTwtOrlMeta?.path ?? "/sostenibilidad",
    meta: indexdh9VTwtOrlMeta || {},
    alias: indexdh9VTwtOrlMeta?.alias || [],
    redirect: indexdh9VTwtOrlMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/sostenibilidad/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Losj9pMCQBMeta?.name ?? "trabaja-con-nosotros-id",
    path: _91id_93Losj9pMCQBMeta?.path ?? "/trabaja-con-nosotros/:id()",
    meta: _91id_93Losj9pMCQBMeta || {},
    alias: _91id_93Losj9pMCQBMeta?.alias || [],
    redirect: _91id_93Losj9pMCQBMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/trabaja-con-nosotros/[id].vue").then(m => m.default || m)
  },
  {
    name: indexl9cUVwUXHtMeta?.name ?? "trabaja-con-nosotros",
    path: indexl9cUVwUXHtMeta?.path ?? "/trabaja-con-nosotros",
    meta: indexl9cUVwUXHtMeta || {},
    alias: indexl9cUVwUXHtMeta?.alias || [],
    redirect: indexl9cUVwUXHtMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/trabaja-con-nosotros/index.vue").then(m => m.default || m)
  },
  {
    name: indexjOPVYRNMTUMeta?.name ?? "user-recovery-password-token1-token2",
    path: indexjOPVYRNMTUMeta?.path ?? "/user/recovery-password/:token1()/:token2()",
    meta: indexjOPVYRNMTUMeta || {},
    alias: indexjOPVYRNMTUMeta?.alias || [],
    redirect: indexjOPVYRNMTUMeta?.redirect,
    component: () => import("/var/www/vhosts/marcosautomocion.es/deploy_tmp/pages/user/recovery-password/[token1]/[token2]/index.vue").then(m => m.default || m)
  }
]